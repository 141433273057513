import React, { useEffect, useRef, useState } from 'react';

const PlanComponent = ({
  selectedPlanData,
  partItems,
  linkedPartClick,
  selectedPartId,
  isLinkingEnabled,
  selectedLinkedParts,
  setSelectedLinkedParts,
  alreadyLinkedParts,
}: any) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (selectedPartId) {
      var currentPart = document.getElementById(selectedPartId);

      if (currentPart) {
        checkActivePart(currentPart);
      }
    }
  }, [selectedPartId]);

  useEffect(() => {
    globalAlreadyLinkedParts();

    if (isLinkingEnabled) {
    } else {
      setSelectedLinkedParts([]);
      removeActiveLinkedParts();
    }

    window.addEventListener('click', partClickEvent);
    return () => {
      window.removeEventListener('click', partClickEvent);
    };
  }, [isLinkingEnabled, selectedPartId]);

  useEffect(() => {
    globalAlreadyLinkedParts();
  }, [alreadyLinkedParts]);

  useEffect(() => {
    newSelectedLinkedParts();
  }, [selectedLinkedParts]);

  const partClickEvent = (event: any) => {
    const className = event?.target?.className?.baseVal;

    if (className?.includes('part-section')) {
      if (event?.target?.dataset) {
        const dataObj = Object.assign({}, event.target.dataset);

        if (dataObj?.id && dataObj?.id != selectedPartId) {
          if (isLinkingEnabled) {
            addLinkedPart(event?.target, dataObj?.id);
          } else {
            linkedPartClick(dataObj?.id);

            checkActivePart(event?.target);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (partItems) {
      const svg: any = svgRef.current;

      svg.setAttribute('viewBox', `0 0 ${selectedPlanData?.width} ${selectedPlanData?.height}`);
      svg.innerHTML = '';

      const svgImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');

      svgImg.setAttribute('height', selectedPlanData?.height);
      svgImg.setAttribute('width', selectedPlanData?.width);
      svgImg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', selectedPlanData?.planImg);
      svgImg.setAttribute('x', '0');
      svgImg.setAttribute('y', '0');

      svg.appendChild(svgImg);

      for (let i = 0; i < partItems?.length; i++) {
        let pathObj = partItems[i];

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

        path.setAttributeNS(null, 'd', pathObj?.dCoordinate);
        path.setAttributeNS(null, 'id', pathObj?.id);
        path.setAttributeNS(null, 'data-id', pathObj?.id);
        path.setAttributeNS(null, 'fill', '#ffffff4f');
        path.setAttributeNS(null, 'stroke', 'green');
        path.setAttributeNS(null, 'class', 'part-section');

        svg.appendChild(path);

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttributeNS(null, 'style', 'fill:red;font-size:25px;');

        const textPath = document.createElementNS('http://www.w3.org/2000/svg', 'textPath');
        textPath.setAttributeNS(null, 'href', `#${pathObj?.id}`);
        textPath.setAttributeNS(null, 'startOffset', '80');
        textPath.textContent = pathObj?.name;

        text.appendChild(textPath);

        svg.appendChild(text);
      }

      // select main part
      if (selectedPartId) {
        var currentPart = document.getElementById(selectedPartId);

        if (currentPart) {
          checkActivePart(currentPart);
        }
      }

      globalAlreadyLinkedParts();
      newSelectedLinkedParts();
    }
  }, [partItems]);

  const checkActivePart = (elem: any) => {
    // get all 'a' elements
    var a = document.getElementsByClassName('part-section');

    for (let i = 0; i < a.length; i++) {
      // Remove the class 'active' if it exists
      a[i].classList.remove('active-part');
    }

    elem.classList.add('active-part');
  };

  const addLinkedPart = (elem: any, id: any) => {
    elem.classList.add('active-linked-part');

    selectedLinkedParts.push(id);
  };

  const removeActiveLinkedParts = () => {
    // get all 'a' elements
    var a = document.getElementsByClassName('part-section');

    for (let i = 0; i < a.length; i++) {
      // Remove the class 'active' if it exists
      a[i].classList.remove('active-linked-part');
    }
  };

  const removeAlreadyLinkedParts = () => {
    // get all 'a' elements
    var a = document.getElementsByClassName('part-section');

    for (let i = 0; i < a.length; i++) {
      // Remove the class 'active' if it exists
      a[i].classList.remove('already-linked-part');
    }
  };

  const globalAlreadyLinkedParts = () => {
    removeAlreadyLinkedParts();

    // select linked parts
    alreadyLinkedParts?.forEach((item: any) => {
      var linkedPartElements = document.querySelectorAll(`[data-id='${item}']`);
      for (let i = 0; i < linkedPartElements.length; i++) {
        // Remove the class 'active' if it exists
        linkedPartElements[i].classList.add('already-linked-part');
      }
    });
  };

  const newSelectedLinkedParts = () => {
    removeActiveLinkedParts();

    // select linked parts
    selectedLinkedParts?.forEach((item: any) => {
      var linkedPartElements = document.querySelectorAll(`[data-id='${item}']`);
      for (let i = 0; i < linkedPartElements.length; i++) {
        // Remove the class 'active' if it exists
        linkedPartElements[i].classList.add('active-linked-part');
      }
    });
  };

  return (
    <div className="container row">
      <div className="col-sm-12">
        <div>
          <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" />
        </div>
      </div>
    </div>
  );
};

export default PlanComponent;
