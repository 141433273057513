import React, { useEffect, useRef, useState } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import PlanComponent from './PlanComponent';
import { Button } from 'antd';

import { readByPlanId } from '../../../api/planAPI';
import { openNotification } from '../../../meta/globalToaster';
import { NOTIFICATION_TYPES } from '../../../const/notificationTypes';
import { PartLinkAPI } from '../../../api/partAPI';

import './plan-page.css';

const PlanPage = ({
  linkedPartClick,
  selectedPartId,
  planList,
  selectedLinkedParts,
  setSelectedLinkedParts,
  alreadyLinkedParts,
}: any) => {
  const [loading, setLoading] = useState(false);

  const [planListData, setPlanListData] = useState([]);

  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedPlanData, setSelectedPlanData] = useState<any>({});

  const [isLinkingEnabled, setIsLinkingEnabled] = useState(false);

  useEffect(() => {
    setPlanListData(planList);
  }, [planList]);

  useEffect(() => {
    if (selectedPlanId) {
      setLoading(true);

      readByPlanId({ id: selectedPlanId }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              setSelectedPlanData(data['data']);

              setLoading(false);
            } else if (data['result'] === 'FAILED') {
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      });
    }
  }, [selectedPlanId]);

  const successSubmission = (selectedPartId: any) => {
    setLoading(false);
    setIsLinkingEnabled(false);
    setSelectedLinkedParts([]);

    linkedPartClick(selectedPartId);

    openNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Updated' });
  };

  const failSubmission = (message: any) => {
    setLoading(false);
    openNotification({ message });
  };

  const updateLinksClick = async () => {
    if (selectedLinkedParts?.length > 0) {
      PartLinkAPI({
        mainPart: selectedPartId,
        linkedList: selectedLinkedParts,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission(selectedPartId);
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      openNotification({ type: NOTIFICATION_TYPES.ERROR, message: 'Select at least one Linked Part' });
    }
  };

  return (
    <div className="container row">
      <div className="row mb-2">
        <div className="col-sm-12 center-v-div">
          {planListData?.map((item: any) => {
            return (
              <Button
                key={item?.id}
                className={`plan-item-button ${selectedPlanId === item?.id ? 'active-plan-item-button' : ''}`}
                onClick={() => {
                  setSelectedPlanId(item?.id);
                }}
                disabled={loading}
              >
                {item?.name}
              </Button>
            );
          })}

          {selectedPartId && (
            <Button
              className="link-enable-button"
              onClick={() => {
                setIsLinkingEnabled(!isLinkingEnabled);
              }}
              disabled={loading}
            >
              {isLinkingEnabled ? 'Disable Linking' : 'Start Linking'}
            </Button>
          )}

          {selectedPartId && isLinkingEnabled && (
            <Button
              className="link-submit-button"
              onClick={() => {
                updateLinksClick();
              }}
              icon={<CaretRightOutlined />}
              disabled={loading}
            >
              Submit Links
            </Button>
          )}
        </div>
      </div>

      {selectedPlanId && (
        <PlanComponent
          selectedPlanData={selectedPlanData}
          partItems={selectedPlanData?.parts}
          linkedPartClick={linkedPartClick}
          selectedPartId={selectedPartId}
          isLinkingEnabled={isLinkingEnabled}
          selectedLinkedParts={selectedLinkedParts}
          setSelectedLinkedParts={setSelectedLinkedParts}
          alreadyLinkedParts={alreadyLinkedParts}
        />
      )}
    </div>
  );
};

export default PlanPage;
