import axios from 'axios';

export const AddPlan = async ({ jobId, imgUrl }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('jobId', jobId);
    formData.append('imgUrl', imgUrl);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/plan/create.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const PlanAreaUpdate = async ({ editedList }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('editedList', JSON.stringify(editedList));
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/plan/area_update.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const PlanExport = async ({ imgList, idList, printedDate }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('imgList', JSON.stringify(imgList));
    formData.append('idList', JSON.stringify(idList));
    formData.append('imgListStr', imgList);
    formData.append('printedDate', printedDate);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/plan/export.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const bulkPartDelete = async ({ idList }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('idList', idList);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/part/bulk-delete.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const readByPlanId = async ({ id }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('id', id);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/plan/read_by_id.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
